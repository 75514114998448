<!-- 现金钱包,账服通钱包 充值,提现 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="font-size18 font-color-333 font-weight700 margin-t-15 flex-row-space-between">
				<div>{{title}}</div>
				<div @click="goJYTwallet" class="buttonStyle">返回</div>
			</div>
			<div class="margin-t-6 recharge-wallet-box position-relative wallet-box">
				<div class="box">
					<div class="card-box flex-row-align-center">
						<div class="user-img-box margin-r-6 flex-row-align-center">
							<imageBox class='user-img' :width='60' :height='60' :borderRidus='50' :src="url"></imageBox>
						</div>
						<div class="flex-colum user-name-box">
							<div class="font-size15 font-weight700">{{ getUserInfo.customerName }}</div>
							<div class="font-size14 margin-t-10 flex-row-wrap">
								<div class="margin-r-5">钱包金额:</div>
								<div class="font-color-FF0000 margin-r-10">{{maxAmount|NumFormat}}元</div>
								<div v-if="type=='withdrawal'" class="color-theme underline pointer" style="margin-right: 10px;" @click="allwithdrawal">全部提现</div>
								<div v-if="type=='withdrawal'" class="color-theme underline pointer" @click="getbalance">刷新</div>
							</div>
						</div>
					</div>
					<el-form ref="form" :rules="rules" :model="form" label-position="right">
						<el-form-item label="提现卡：" prop="fAccountID">
							<el-select v-model="form.fAccountID" placeholder="请选择提现卡" ref="fRefundMode">
								<el-option style="width: 220px;" v-for="(item,index) in fRefundModeList" :key="index" :label="item.fShowName"
								 :value="item.fAccountID"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
					<div class="recharge-amount-box flex-row-align-center border-bottom-F2F2F2">
						<div class="font-size14 font-color-333 recharge-amount-text">{{type=='recharge'?'充值':'提现'}}金额:</div>
						<div class="font-size30 font-color-333">
							￥
						</div>
						<el-input ref="amount" :autofocus="true" v-model="amount" @input="setOnlyMoney" :maxlength="13"></el-input>
					</div>
					<div v-loading='buttonLoading' class="recharge-button font-size14 background-color-theme flex-row-center-center pointer"
					 @click="changePayment">立即{{type=='recharge'?'充值':'提现'}}</div>
				</div>

			</div>

		</div>
		
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import imageBox from '@/components/imageBox/imageBox.vue'
	export default {
		components: {
			imageBox,
		},
		data() {

			return {
				numberOfPassword: 0, //输入密码次数
				pwdList: [], //密码数组
				password: '', //支付密码
				PasswordVisible: false, //支付弹窗
				rules: {
					fAccountID: [{
						required: true,
						message: '请选择提现卡',
						trigger: 'change'
					}],
				},
				form: {
					fAccountID: '',
				},
				fRefundModeList: [],
				title: '', //标题
				type: '', //类型 充值:recharge  提现:withdrawal
				walletType: '', //钱包类型 现金钱包:XJ 账服通钱包:ZFT
				amount: '', //金额
				maxAmount: 0, //账户余额
				fLockMoney:0,//锁定金额
				url: require("@/assets/personal.png"),
				buttonLoading: false, //按钮加载
				actualAmount: '', //不带千分符的金额
				
				canReload: true
			};
		},
		created() {
		  var that = this
		  //当前页面监视键盘输入
		  document.onkeydown = function(e) { //事件对象兼容
		    // console.log(e, e.key);
		    if (that.PasswordVisible && that.$route.fullPath == '/paymentOrder') {
		      if (e.key == '0' || e.key == '1' || e.key == '2' || e.key == '3' || e.key == '4' || e.key == '5' || e.key ==
		        '6' ||
		        e.key == '7' || e.key == '8' || e.key == '9') {
		        if (that.pwdList.length < 6) {
		          that.pwdList.push(e.key)
		          that.password = that.pwdList.join('')
		        }
		        if (that.pwdList.length == 6) {
		          that.numberOfPassword = that.numberOfPassword + 1;
		        }
		      } else if (e.key == 'Backspace') {
		        that.pwdList.pop()
		      }
		    }
		  }
		},
		mounted() {
			this.$refs.amount.focus();
			let data = JSON.parse(decodeURIComponent(this.$route.query.data));
			this.type = data.type;
			this.walletType = data.walletType;
			if (this.walletType == 'JYT') {
				this.title = '交易通提现'
				this.getfRefundModeList()
				this.getBalanceZFT()
			}
			if (this.getUserInfo.fFileName) {
				this.url = this.getUserInfo.fFileName
			}
		},
		computed: {
			...mapGetters(["getThemeName", "getUserInfo"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			goJYTwallet() {
				this.$router.push({
					path: "/businessme/JYTwallet",
					query: {
						
					},
				});
			},
			//查看余额
			getbalance() {
				if(this.canReload) {
					this.updateCountdown(60)
					// this.maxAmount = 0
					this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-jyt-account-balance')
					  .then(res => {
					    console.log("现金钱包余额刷新", res);
					   this.maxAmount = res.obj.avibal;
					  }, error => {});
					this.canReload = false
				} else {
					this.$message.warning('每一分钟可查询余额，请勿频繁操作')
				}
			},
			updateCountdown(secondsRemaining) {
			  // 显示倒计时
			 
			  // 如果还有剩余时间，则递归调用updateCountdown
			  if (secondsRemaining > 0) {
			    setTimeout(() => {
			      this.updateCountdown(secondsRemaining - 1);
			    }, 1000);
			  } else {
			    // 倒计时结束的操作
			  	this.canReload = true
			  }
			},
			//获取下拉框的数据
			getfRefundModeList() {
				this.ApiRequestPostNOMess('/api/mall/ebcustomer/bank-account/get-jyt-physical-account-list').then(result => {
					if (result.obj.length > 0) {
						this.fRefundModeList = result.obj
					}
				}, rej => {})
			},
			//获取账服通余额
			getBalanceZFT() {
				// this.maxAmount = 0
				this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-jyt-account-balance')
					.then(res => {
						console.log("交易通余额", res);
						if (res.obj) {
							this.maxAmount = res.obj.avibal;
						}
					}, error => {});
			},
			
			
			//全部提现
			allwithdrawal() {
				let val = this.maxAmount.toString()
				this.actualAmount = this.maxAmount.toString()
				let num = val.split("."); // 分隔小数点
				let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
				let res = [];
				for (let i = 0, len = arr.length; i < len; i++) {
					if (i % 3 === 0 && i !== 0) {
						res.push(","); // 添加分隔符
					}
					res.push(arr[i]);
				}
				res.reverse(); // 再次倒序成为正确的顺序
				if (num[1]) { // 如果有小数的话添加小数部分
					if (num[1].length == 1) {
						res = res.join("").concat("." + num[1] + '0');
					} else {
						res = res.join("").concat("." + num[1]);
					}
				} else {
					res = res.join("").concat("." + "00");
				}
				this.amount = res;
			},
			//输入金额限制
			setOnlyMoney() {
				this.$nextTick(() => {
					let val = this.amount.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || '';
					this.actualAmount = val
					let res = [];
					if (val.includes(".")) {
						let num = val.split("."); // 分隔小数点
						let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
						for (let i = 0, len = arr.length; i < len; i++) {
							if (i % 3 === 0 && i !== 0) {
								res.push(","); // 添加分隔符
							}
							res.push(arr[i]);
						}
						res.reverse(); // 再次倒序成为正确的顺序
						if (num[1]) { // 如果有小数的话添加小数部分
							res = res.join("").concat("." + num[1]);
						} else {
							res = res.join("").concat(".");
						}
					} else {
						let num = val.split("."); // 分隔小数点
						let arr = num[0].split("").reverse(); // 转换成字符数组并且倒序排列
						for (let i = 0, len = arr.length; i < len; i++) {
							if (i % 3 === 0 && i !== 0) {
								res.push(","); // 添加分隔符
							}
							res.push(arr[i]);
						}
						res.reverse(); // 再次倒序成为正确的顺序
						res = res.join("");
					}
					this.amount = res;
				});
			},
			changePayment() {
				if (!this.actualAmount) {
					this.$message('请输入金额');
					return
				}
				if (this.actualAmount == 0) {
					if (this.type == 'withdrawal') {
						this.$message('提现金额不能为0');
					} else {
						this.$message('充值金额不能为0');
					}
					return
				}
				if (this.walletType == 'JYT') { //账服通钱包
					if (this.type == 'withdrawal') { //账服通提现
						if (this.actualAmount > this.maxAmount) {
							this.$message('您所提现的金额超出当前账户余额，请重新输入');
						} else if (this.actualAmount > 99999999.99) {
							this.$message('最大提现金额为99,999,999.99.99，请重新输入');
						} else {
							this.$refs.form.validate((valid) => {
								if (valid) {
									this.customerPaymentBillZFTTX();
								} else {
									return false;
								}
							});
						}
					}
				}
			},
			//账服通提现
			customerPaymentBillZFTTX() {
				this.ApiRequestPostNOMess('api/mall/ebsale/refund-bill/create-jyt-refund', {
						fMoney: this.actualAmount,
						fAppTypeID: this.fAppTypeID,
						fAccountID: this.form.fAccountID
					})
					.then(res => {
						console.log("账服通提现___完成", res);
						// Auditing字段 1表示审核中 0表示初始化 254 表示审核通过
						if (res.obj.status) {
							let obj = {
								successType: 'TX',
								examine: res.obj.Auditing,
								amount: this.actualAmount,
								billNumber: res.obj.fRefundBillNumber,
								type: 'JYT',
								fBillTypeID: '200015019',
							}
							this.$router.replace({
								path: '/businessme/RechSuccess',
								query: {
									// data: encodeURIComponent(JSON.stringify(obj))
									data: JSON.stringify(obj)
								}
							})
						} else {
							this.$message({
								message: res.obj.message,
								type: 'warning'
							});
						}
					}, error => {});
			},
		
		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.card-box {
		// width: 350px;
		height: 100px;
	}

	.user-name-box {
		color: #1F4E9E;
	}

	.user-img-box {
		width: 80px;
		height: 80px;
	}

	.user-img {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	}

	.recharge-wallet-box {
		// padding: 14px 0 0 20px;
		width: 897px;
		height: 378px;
		background: url(../../../assets/imgs/pay/web01.jpg) no-repeat;
		// background-size: 100% 378px;

	}

	.wallet-box {
		height: 400px;
		box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
		box-sizing: border-box;
		padding-top: 30px;
		margin-top: 10px;
		border-radius: 5px;
	}

	.box {
		min-height: 260px;
		width: 255px;
		margin-left: 95px;
		padding: 20px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		background-color: #FFFFFF;
	}

	.back-card-logo-img {
		width: 50px;
		height: 20px;
		background-color: #CCCCCC;
	}

	.changeBankCard:hover {
		cursor: pointer;
	}

	.recharge-amount-box {
		margin-top: 20px;
		height: 44px;
		width: 255px;
		padding-bottom: 10px;
	}

	.recharge-amount-text {
		width: 70px;
	}

	.recharge-button {
		margin: 40px 0 0 0px;
		// width: 235px;
		width: 255px;
		height: 45px;
		border-radius: 0px;
	}

	.underline {
		text-decoration: underline;
	}

	.recharge-amount-box::v-deep {
		.el-input__inner {
			// width: 100px;
			height: 44px !important;
			font-size: 24px;
			padding: 0;
			color: #000000;
			border: none !important;
			background-color: rgba(255, 255, 255, 0);
		}

		.el-input {
			width: 155px;
		}
	}

	/deep/ .el-form-item__label {
		padding: 0;
	}

	/deep/ .el-select {
		width: 185px;
	}
	.notice-content {
		width:100%;
		overflow: hidden;
	}
	.noticeBtn{
		width:150px;
		line-height: 35px;
		background-color: #005CC5;
		color:white;
		text-align: center;
	}
	.noticeContent{
		font-size: 18px;
		margin-top: 20px;
		text-align: center;
	}
	/deep/ .el-dialog{
		margin-top: 30vh !important;
	}
	.buttonStyle {
	    width: 110px;
	    height: 30px;
	    background-color: #ffffff;
		color:#0173FE;
		border: 1px solid #0173FE;
		font-weight: 400;
		font-size: 13px;
	    border-radius: 2px;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    justify-content: center;
	}
</style>
